.Header {
  width: 100%;
  border-bottom: 2px solid #3a3a3c;
}

h1 {
  font-weight: 900;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
