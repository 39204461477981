.Keyboard {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 8px 4px;
}

.Keyboard-row {
  display: flex;
  width: 100%;
  margin: 0 auto 8px;
}

.Keyboard-cell {
  display: flex;
  height: 50px;
  border: 2px solid #3a3a3c;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 0;
  border-radius: 4px;
  margin: 0 3px 0 3px;
  background-color: #818384;
  color: #fff;
  font-weight: bold;
  padding: 0;
}
