.Board {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.Board-game {
  width: 70%;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  box-sizing: border-box;
  padding: 10px;
}

.Board-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  box-sizing: border-box;
}

.Board-cell {
  font-size: 2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-weight: bold;
  vertical-align: middle;
  border: 2px solid #3a3a3c;
  box-sizing: border-box;
  aspect-ratio: 1;
}

@keyframes horizontal-shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation-name: horizontal-shake;
  animation-duration: 0.5s;
  -webkit-animation-name: horizontal-shake;
  -webkit-animation-duration: 0.5s;
}

@keyframes vertical-hop {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.vertical-hop {
  animation-name: vertical-hop;
  animation-duration: 2s;
  -webkit-animation-name: vertical-hop;
  -webkit-animation-duration: 0.5s;
}

@media screen and (max-width: 1200px) {
  .Board-game {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .Board-game {
    width: 90%;
  }
}
